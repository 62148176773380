<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
      <div class="info-card">
        <p style="font-size:25px">👨🏻‍🌾</p>
        <div class="info-text" style="color:rgba(0, 0, 0, 0.5);font-size:13px">
          <div>
            Producteurs
          </div>
          <count-to :start-val="0" :end-val="totalProducteur" :duration="2600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
      <div class="info-card">
        <p style="font-size:50px">👷🏿‍♂️</p>
        <div class="info-text" style="color:rgba(0, 0, 0, 0.5);font-size:13px">
          <div>
            Controleurs
          </div>
          <count-to :start-val="0" :end-val="totalControleurs" :duration="2600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="5" class="card-panel-col">
      <div class="info-card">
        <p style="font-size:50px">🗺</p>
        <div style="color:rgba(0, 0, 0, 0.5);font-size:13px" class="info-text">
          <div>
            Parcelles
          </div>
          <div style="display:flex; flex-direction:row">
            <!-- <count-to style="margin:0 5px 0 0" :start-val="0" :end-val="totalParcelles" :duration="2600" class="card-panel-num" /> -->
            {{ totalParcelles }} <br>soit {{ totalHectares }} (ha)
            <!-- <count-to style="margin:0 5px" :start-val="0" :val="totalHectares" :duration="2600" class="card-panel-num" /> (ha) -->
          </div>

        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="9" class="card-panel-col">
      <div class="info-card">
        <p style="font-size:50px">⁉</p>
        <div style="display:flex; flex-direction:row; font-weight:bold; color:rgba(0, 0, 0, 0.5)">
          <div style="display:flex; flex-direction:column; align-items:center; font-size:13px">
            <p>Audit</p>
            <count-to :start-val="0" :end-val="totalAudits" :duration="2600" class="card-panel-num" />
          </div>
          <div style="display:flex; flex-direction:column; align-items:center; margin:0 20px; font-size:13px">
            <p>C.I.</p>
            <count-to :start-val="0" :end-val="totalControleInternes" :duration="2600" class="card-panel-num" />
          </div>
          <div style="display:flex; flex-direction:column; align-items:center; margin:0 20px; font-size:13px">
            <p>C.I 1</p>
            <count-to :start-val="0" :end-val="totalCiUn" :duration="2600" class="card-panel-num" />
          </div>
          <div style="display:flex; flex-direction:column; align-items:center; margin:0 20px; font-size:13px">
            <p>C.I 2</p>
            <count-to :start-val="0" :end-val="totalCiDeux" :duration="2600" class="card-panel-num" />
          </div>
          <div style="display:flex; flex-direction:column; align-items:center; margin:0 20px; font-size:13px">
            <p>C.I 3</p>
            <count-to :start-val="0" :end-val="totalCiTrois" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to';

export default {
  components: {
    CountTo,
  },
  props: {
    totalProducteur: {
      type: Number,
      default: () => 0,
    },
    totalControleurs: {
      type: Number,
      default: () => 0,
    },
    totalParcelles: {
      type: Number,
      default: () => 0,
    },
    totalHectares: {
      type: Number,
      default: () => 0,
    },
    totalControleInternes: {
      type: Number,
      default: () => 0,
    },
    totalAudits: {
      type: Number,
      default: () => 0,
    },
    totalCiUn: {
      type: Number,
      default: () => 0,
    },
    totalCiDeux: {
      type: Number,
      default: () => 0,
    },
    totalCiTrois: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.info-card {
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:space-between;
  background-color:#fff;
  height: 108px;
  padding:16px 45px;
}
.info-text {
  display:flex;
  flex-direction:column;
  font-weight: bold;
}
.panel-group {
  margin-top: 18px;
  .card-panel-col{
    margin-bottom: 32px;
  }
  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    // position: relative;
    // overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    &:hover {
      // .card-panel-icon-wrapper {
      //   color: #fff;
      // }
      // .icon-people {
        //  background: #40c9c6;
      // }
      // .icon-message {
      //   background: #36a3f7;
      // }
      // .icon-money {
      //   background: #f4516c;
      // }
      // .icon-shopping {
      //   background: #34bfa3
      // }
    }
    // .icon-people {
    //   color: #40c9c6;
    // }
    .icon-message {
      color: #36a3f7;
    }
    .icon-money {
      color: #f4516c;
    }
    .icon-shopping {
      color: #34bfa3
    }
    .card-panel-icon-wrapper {
      float: left;
      // margin: 0 auto;
      // padding: 16px;
      padding: 0 0 0px 40px;
      transition: all 0.38s ease-out;
      // border-radius: 6px;
      // display: flex;
      // align-items: center;
    }
    .card-panel-icon {
      float: left;
      font-size: 48px;
    }
    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      // margin-left: 0px;
      .card-panel-text {
        // line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }
      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}
</style>
