<template>
  <div class="dashboard-editor-container">
    <link href="https://api.mapbox.com/mapbox-gl-js/v3.1.2/mapbox-gl.css" rel="stylesheet">
    <panel-group
      :total-producteur="data.producteurs"
      :total-controleurs="data.controleurs"
      :total-parcelles="data.parcelles"
      :total-hectares="data.total_hectare"
      :total-audits="data.total_audit_termine"
      :total-controle-internes="data.total_ci_termine"
      :total-ci-un="data.total_ci_1"
      :total-ci-deux="data.total_ci_2"
      :total-ci-trois="data.total_ci_3"
      :total-nbre-sacs="data.nombre_sac"
      @handleSetLineChartData="handleSetLineChartData"
    />
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom: 50px; height: 200px;">
      <div class="card-panel-text">
        <b style="color: #00ab53">Géolocalisation des parcelles</b><br/><br/>
      </div>
      <div style="display:flex; flex-direction:row">
        <el-form label-position="left" style="margin-right: 5px;">
          <el-form-item label="Unité de production">
            <el-select v-model="currentUP" border class="filter-item" placeholder="Unité de production" @change="filterByUniteProduction">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(up, idex) in unite_productions" :key="idex" :label="up.nom | uppercaseFirst" :value="up.id" :disabled="!up.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Groupement">
            <el-select v-model="currentGroupement" border class="filter-item" placeholder="Groupement" @change="filterByGroupement">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(groupement, idex) in groupements" :key="idex" :label="groupement.nom | uppercaseFirst" :value="groupement.id" :disabled="!groupement.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Producteur">
            <el-select v-model="currentProducteur" border class="filter-item" placeholder="Producteur">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(producteur, idex) in producteurs" :key="idex" :label="producteur.nom + ' ' + producteur.prenom | uppercaseFirst" :value="producteur.id" :disabled="!producteur.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Région">
            <el-select v-model="currentRegion" border class="filter-item" placeholder="Région" @change="filterByRegion">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(region, idex) in regions" :key="idex" :label="region.nom | uppercaseFirst" :value="region.id" :disabled="!region.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Zone">
            <el-select v-model="currentZone" border class="filter-item" placeholder="Zone" @change="filterByZone">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(zone, idex) in zones" :key="idex" :label="zone.nom | uppercaseFirst" :value="zone.id" :disabled="!zone.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Commune">
            <el-select v-model="currentCommune" border class="filter-item" placeholder="Commune" @change="filterByCommune">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(commune, idex) in communes" :key="idex" :label="commune.nom | uppercaseFirst" :value="commune.id" :disabled="!commune.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Village">
            <el-select v-model="currentVillage" border class="filter-item" placeholder="Village">
              <el-option key="-1" label="--" value="" />
              <el-option v-for="(village, idex) in villages" :key="idex" :label="village.nom | uppercaseFirst" :value="village.id" :disabled="!village.is_active" />
            </el-select>
          </el-form-item>
        </el-form>
        <el-form  label-position="left" style="margin-right: 5px;">
          <el-form-item label="Statut parcelle">
            <el-select v-model="currentStatut" border class="filter-item" placeholder="Statut parcelle">
              <el-option key="-1" label="--" value="" />
              <el-option key="0" label="AB" value="AB" />
              <el-option key="1" label="C1" value="C1" />
              <el-option key="2" label="C2" value="C2" />
              <el-option key="3" label="C3" value="C3" />
            </el-select>
          </el-form-item>
        </el-form>
        <!-- <el-button v-waves id="btn_faire_filtre_map" class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="filtrerMap"></el-button> -->
      </div>
      <el-form  label-position="left" style="margin-right: 5px;">
        <el-button id="btn_faire_filtre_map" icon="el-icon-search" type="primary" class="btn-control-simple" size="small" @click="filtrerMap">Géolocaliser</el-button>
        <el-button id="btn_faire_filtre_map" icon="el-icon-close" size="small" @click="clearfiltrerMap"></el-button>
      </el-form>
    </el-row>
    <el-row v-loading="loading" style="background:#fff; padding:16px 16px 0; height: 650px;margin-bottom: 30px;">
      <div class="card-panel-text">
        <b style="color: #00ab53"><count-to :start-val="0" :end-val="coordonnees_parcelle_size" :duration="2600" class="card-panel-num" /> Parcelles géolocalisées - <count-to :start-val="0" :end-val="total_superficie" :duration="2600" class="card-panel-num" /> ha</b><br/><br/>
      </div>
      <!-- <line-chart :chart-data="lineChartData" /> -->
      <!-- <img class="pic-404__parent" :src="fondculturesoja" alt="404"> -->
      <!-- <div> -->
      <!-- <div id="map-container"> -->
      <div id="mapContainer" />
      <!-- </div> -->
      <!-- <button class="btn-control" @click="voirCarte">Voir la parcelle</button> -->
      <!-- </div> -->
    </el-row>

    <!-- <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <raddar-chart />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <pie-chart />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <bar-chart />
        </div>
      </el-col>
    </el-row> -->
  </div>
</template>
<script>
import Resource from '@/api/resource';
import PanelGroup from './components/PanelGroup';
import fondculturesoja from '@/assets/images/fondculturesoja.png';
import mapboxgl from 'mapbox-gl';
import CountTo from 'vue-count-to';

const DashboardResource = new Resource('accueil');
const uniteProductionResource = new Resource('unite_productions');
const groupementResource = new Resource('groupements');
const producteurResource = new Resource('producteurs-all');
const regionResource = new Resource('regions');
const zoneResource = new Resource('zones');
const communeResource = new Resource('communes');
const villageResource = new Resource('villages');
const coordonneeResource = new Resource('coordonnees/recherche');

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145],
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130],
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130],
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130],
  },
};

export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup,
    CountTo,
    // LineChart,
    // RaddarChart,
    // PieChart,
    // BarChart,
  },
  data() {
    return {
      lineChartData: lineChartData.newVisitis,
      fondculturesoja: fondculturesoja,
      currentUP: null,
      currentGroupement: null,
      currentProducteur: null,
      currentRegion: null,
      currentZone: null,
      currentCommune: null,
      currentVillage: null,
      currentStatut: null,
      loading: false,
      total_superficie: 0,
      unite_productions: [],
      groupements: [],
      producteurs: [],
      regions: [],
      zones: [],
      communes: [],
      villages: [],
      coordonnees_parcelle: [],
      coordonnees_parcelle_size: 0,
      accessToken: 'pk.eyJ1IjoiZGV2b25lc295IiwiYSI6ImNsMTR0c2Y0MDBkdWIzZHF6cjk5OWJ1NnMifQ.cEu2E93WCC2rY74DEjqixg',
      data: null,
      query: {
        page: 1,
        limit: 3000,
        keyword: '',
      },
    };
  },
  created() {
    this.getData();
    this.callBaseApi();
    this.filtrerMap();
    // this.voirCarte();
  },
  methods: {
    clearfiltrerMap() {
      this.currentUP = null; this.currentGroupement = null; this.currentProducteur = null; this.currentRegion = null; this.currentZone = null; this.currentCommune = null; this.currentVillage = null; this.currentStatut = null;
      this.callBaseApi();
      this.filtrerMap();
    },
    callBaseApi() {
      this.getUniteProductionsList();
      this.getGroupementList();
      // this.getProducteurList();
      this.getRegionList();
      this.getZoneList();
      this.getCommuneList();
      this.getVillageList();
    },
    async filtrerMap() {
      this.loading = true;
      const subUrl = '?unite_production_id=' + this.currentUP + '&groupement_id=' + this.currentGroupement + '&producteur_id=' + this.currentProducteur + '&region_id=' + this.currentRegion + '&zone_id=' + this.currentZone + '&commune_id=' + this.currentCommune + '&village_id=' + this.currentVillage + '&statut=' + this.currentStatut;
      const { data } = await coordonneeResource.sublist(subUrl, this.query);
      this.coordonnees_parcelle = this.coordonneesList(data);
      this.coordonnees_parcelle_size = data.length;
      this.loading = false;
      this.voirCarte();
    },
    async filterByUniteProduction() {
      const { data } = await groupementResource.sublist('?unite_production_id=' + this.currentUP, this.query);
      this.groupements = data;
    },
    async filterByGroupement() {
      const { data } = await producteurResource.sublist('?groupement_id=' + this.currentGroupement, this.query);
      this.producteurs = data;
    },
    async filterByRegion() {
      const { data } = await zoneResource.sublist('?region_id=' + this.currentRegion, this.query);
      this.zones = data;
    },
    async filterByZone() {
      const { data } = await communeResource.sublist('?zone_id=' + this.currentZone, this.query);
      this.communes = data;
    },
    async filterByCommune() {
      const { data } = await villageResource.sublist('?commune_id=' + this.currentCommune, this.query);
      this.villages = data;
    },
    handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    async getUniteProductionsList() {
      const { data } = await uniteProductionResource.sublist('?typeApi="all"', this.query);
      this.unite_productions = data;
      this.voirCarte();
    },
    async getGroupementList() {
      const { data } = await groupementResource.sublist('?typeApi="all"', this.query);
      this.groupements = data;
    },
    async getProducteurList() {
      const { data } = await producteurResource.sublist('?typeApi="all"', this.query);
      this.producteurs = data;
    },
    async getRegionList() {
      const { data } = await regionResource.sublist('?typeApi="all"', this.query);
      this.regions = data;
    },
    async getZoneList() {
      const { data } = await zoneResource.sublist('?typeApi="all"', this.query);
      this.zones = data;
    },
    async getCommuneList() {
      const { data } = await communeResource.sublist('?typeApi="all"', this.query);
      this.communes = data;
    },
    async getVillageList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
    },
    async getData() {
      const { data } = await DashboardResource.list(this.query);
      this.data = data;
      // console.log('5555588: ', this.data.total_ci_3);
    },
    coordonneesList(parccelleCoords) {
      var cords = [];
      var historique = '';
      var culture = '';
      var total_superficie = 0;
      parccelleCoords.forEach(element => {
        // cords.push([element.latitude, element.longitude]);
        historique = '';
        total_superficie += parseFloat(element.superficie);
        element.historiques.forEach((hist) => {
          culture = hist.culture_pratique == null ? '' : hist.culture_pratique;
          historique += hist.annee + ' : ' + culture + '<br/>';
        });
        cords.push({
          'type': 'Feature',
          'properties': {
            'description': '<strong>Produteur: ' + element.producteur + '</strong><p><hr>Statut: ' + element.statut + '<br/> Superficie: ' + element.superficie + ' ha <hr> Village: ' + element.village + ' <br/> Commune: ' + element.commune + '<br/> Zone: ' + element.zone + '<br/> Région: ' + element.region + ' <hr> Groupement: ' + element.groupement + ' <br/> Unité de production: ' + element.unite_production + ' <hr> <b>Historique</b> <br/>' + historique + '</p>',
          },
          'geometry': {
            'type': 'Point',
            'coordinates': [element.latitude, element.longitude],
          },
        });
      });
      var lastValue = cords.shift(); // Retire la dernière valeur de la liste
      cords.push(lastValue);
      this.total_superficie = total_superficie;
      return cords;
    },
    voirCarte() {
      // if (tab.label === 'Cartographie') {
      mapboxgl.accessToken = this.accessToken;
      const nav = new mapboxgl.NavigationControl();
      const map = new mapboxgl.Map({
        container: 'mapContainer',
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [0.824782, 8.619543],
        zoom: 5,
      });

      map.on('load', () => {
        map.addSource('places', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': this.coordonnees_parcelle,
          },
        });
        // Add a layer showing the places.
        map.addLayer({
          'id': 'places',
          'type': 'circle',
          'source': 'places',
          'paint': {
            'circle-color': '#00ab53',
            'circle-radius': 6,
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
          },
        });

        // Create a popup, but don't add it to the map yet.
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
        });

        map.on('mouseenter', 'places', (e) => {
          // Change the cursor style as a UI indicator.
          map.getCanvas().style.cursor = 'pointer';

          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          const description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(description).addTo(map);
        });

        map.on('mouseleave', 'places', () => {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });
      });

      map.addControl(new mapboxgl.FullscreenControl());
      map.addControl(nav, 'top-right');
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      });
      map.addControl(geolocate, 'top-right');
      // }
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

.basemap {
  height: 400px;
}

#mapContainer {
  height: 100%;
  width: 100%;
}

#map-container {
  height: 70vh;
  width: auto;
}

.mapboxgl-canvas {
  width: 70vh !important;
  height: auto !important;
}

.btn-control {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background-color: #3386c0;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: 1;
  border: none;
  width: 200px;
  margin-left: -100px;
  display: block;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 3px;
}

.btn-control-simple {
  background-color: #3386c0;
  color: #fff;
  z-index: 1;
  border: none;
  cursor: pointer;
  height: 40px;
  border-radius: 3px;
}

.btn-control:hover {
  background-color: #4ea0da;
}
.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
</style>
